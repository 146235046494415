import './registerServiceWorker'
import {createApp} from 'vue'
import {Chart, registerables} from 'chart.js';
import {createPinia} from 'pinia'
import moment from "moment"
import axios from 'axios'
import VXETable from 'vxe-table'
import VueAxios from 'vue-axios'
import ViewUIPlus from 'view-ui-plus'
import 'chartjs-adapter-moment';
import 'vxe-table/lib/style.css'
import 'view-ui-plus/dist/styles/viewuiplus.css'

import '@/plugins/gs-plugins/base.styl';
import './assets/css/app.styl';

import App from './App.vue'
import dataProxy from '@/plugins/dataProxy'
import globalState from '@/plugins/gs-plugins/globalState'
import viewState from '@/plugins/view'
import operating from '@/operating.js'

const pinia = createPinia()
const app = createApp(App)

app
    .use(pinia)
    .use(VXETable)
    .use(VueAxios, axios)
    .use(ViewUIPlus)
    .use(viewState)
    .use(operating)
    .use(dataProxy)
    .use(globalState)

Chart.register(...registerables);
moment.locale('zh-tw');

app.config.globalProperties.Chart = Chart
app.config.globalProperties.$moment = moment
app.mount('#app')