import {ref} from "vue";
import axios from "axios";

export default {
    install: (app) => {
        app.config.globalProperties.dataPool = new DataPool(app);
    },
}

export {DataPool};

class DataPool {
    constructor(app) {
        this.url = 'wss://conn-dot-greenshepherdcomtw.appspot.com';
        // this.url = 'ws://localhost:3000';
        this.mac = Math.random().toString(36).substring(3);
        this.app = app
        this.socket = null
        this.fieldList = []
        this.clientsList = ref([]);
        // 資料池
        this.dataPoolReal = ref({});
        // WebSocket 實例
        this.initWebSocket();
    }

    getValue = (key) => {  // 使用箭頭函數以保持 this 綁定
        const keys = key.split('.');
        let currentLevel = this.dataPoolReal.value;  // 使用 .value 來訪問 ref 的值
        for (let k of keys) {
            if (currentLevel[k] === undefined) {
                return 'N/A';
            }
            currentLevel = currentLevel[k];
        }
        // 返回找到的值。這裡不再單獨對數字進行判斷，直接返回結果。
        return currentLevel;
    };
    sendCommand = (command) => {
        const fieldId = this.app.config.globalProperties.view.currentFieldId;
        if (!fieldId){
            return
        }
        if (typeof command === 'object'){
            const data = {
                type: 'command',
                fieldId: fieldId,
                ...command
            }
            this.send(JSON.stringify(data))
        }
    }

    send = (message) => {
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            this.socket.send(message);
        } else {
            console.log("WebSocket is not open. Message not sent.");
        }
    }
    clients = () => {
        return this.clientsList.value;
    }

    getInfo = () =>{
        let url = (this.url + `/info`).replace('wss', 'https').replace('ws', 'http');
        axios.get(url).then(res =>{
            this.clientsList.value = res.data.clients;
        }).catch(err => {
            this.error = err;
        });
    }

    updateFieldStatus = (data) => {
        if ('field_id' in data) {
            const fieldId = data.field_id;
            const fieldIndex = this.fieldList.findIndex(f => f.field_id === fieldId);
            if (fieldIndex >= 0) {
                let status = 'online';
                if ('datetime' in data) {
                    let d = new Date(data.datetime);
                    // if d is before not by 15 seconds, then set status to 'offline'
                    if (new Date() - d > 15 * 1000) {
                        status = 'offline';
                    }
                }
                this.fieldList[fieldIndex] = {
                    'status': status,
                    'datetime': data.datetime,
                }
            }
            this.dataPoolReal.value[`field_${fieldId}`] = data;
        }
        // this.dataPoolReal.value = {...this.dataPoolReal.value, ...data};  // 使用 .value 更新 ref 的值
    }

    reconnectWebSocket = () => {
        setTimeout(this.initWebSocket, 5000);
    }

    sendFieldIdToCloud = () => {
        console.log("WebSocket 已連線");
        this.socket.send(JSON.stringify({
            'type': 'field_id',
            'fieldId': 0,
            'mac': this.mac,
        }));
    }

    // 初始化 WebSocket
    initWebSocket = () => {
        this.socket = new WebSocket(this.url + '/conn', 'protocol-web-client');

        // 監聽事件
        this.socket.onopen = this.sendFieldIdToCloud;

        this.socket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            this.updateFieldStatus(data);
        };

        this.socket.onclose = this.reconnectWebSocket;

        this.socket.onerror = function (error) {
            console.log(`WebSocket 錯誤: ${error}`);
        };
    };

}