<template>
    <Apps :platformName="'維運派工'" style="z-index: 99; top: -8px;"></Apps>
    <div id="layout" :content="pageName" :class="{[pageName]: true, 'isTablet': view.isTablet, 'isMobile': view.isMobile, 'loginPage': !view.isLogin}">
        <div id="nav">
            <div id="logo" @click="logoClick"></div>
            <div class="title" @click="logoClick" v-show="!view.isTablet">
                維運派工
            </div>
            <div class="menus" v-if="view.isLogin">
                <div v-for="k in navData" class="mainMenuItem"
                     @click="mainMenuSelect($event, k)"
                     @mouseover="mainMenuMouseOver($event, k)"
                     :class="{active: k.name === view.mainMenuName}" :key="k.name">
                    {{ k.title }}
                </div>
            </div>
        </div>
        <Sider id="sidebar" class="subMenu" v-show="view.isLogin && view.showSubMenu">
            <div :style="{height: '46px', lineHeight: '44px', textAlign: 'left', borderBottom: 'solid 1px #ccc'}">
                <Button class="fieldSelectorButton" @click="toggleSelector()" v-if="view.isLogin">
                    {{ view.targetInfo }}
                    <Badge class-name="badge-alone" overflow-count="9999" v-if="view.selectTarget === '全部案場'" :count="view.selectFieldList.length" style="top: -14px;right: -8px;">
                        <a href="#" class="demo-badge"></a>
                    </Badge>
                </Button>
            </div>
            <List>
                <router-link class="ivu-list-item" :class="{'active': view.pageName === i.dir + '_' + i.page, 'disabled': !checkMenuAvailable(i)}" :to="`/${i.dir}/${i.page}`" :key="i.page" v-for="i in menuData">{{ i.title }}</router-link>
            </List>
        </Sider>
        <div class="mobileMenu" v-show="view.isTablet && view.showSubMenu" :style="{right: menuRightSideWidth, height: menuHeight}">
            <List>
                <router-link @click="view.showSubMenu = false" class="ivu-list-item" :class="{'active': view.pageName === i.dir + '_' + i.page}"
                             :to="`/${i.dir}/${i.page}`" :key="i.page" v-for="i in menuData">{{ i.title }}
                </router-link>
            </List>
        </div>

        <Layout id="content">
            <div class="subMenuSelectorMask" v-show="view.isMobile && view.showSubMenu" @click="view.showSubMenu = false"></div>
            <div v-if="view.isLogin">
                <router-view v-if="isRouterAlive"></router-view>
            </div>
            <div v-else>
                <Card style="padding: 50px; width: 25%; min-width: 400px; margin: 50px auto;">
                <h1>歡迎回來</h1>
                <img src="@/assets/th.jpg" alt="loginSide" style="width: 100%; max-width: 400px; margin: 0 auto; display: block;">
                </Card>
            </div>
        </Layout>

        <div class="fieldSelectorMask" v-show="showSelector" @click="doSelect({'name': view.selectTarget}); this.showSelector = false"></div>
        <div class="fieldSelectorBox" v-show="showSelector">
            <div><Input type="text" placeholder="請輸入案場地址或名稱…" v-model="view.searchKeyword"></Input></div>
            <div class="fieldSelector">
                <div class="entityPanel selectorArea1">
                    <h3>篩選</h3>
                    <ul>
                        <template v-for="f in view.filterTypeList" :key="f">
                            <li v-if="f.key !== 'hr'" :class="[{'active': f.title === view.selectFilter}, f.class]" @click="view.selectFilter = f.title">
                                {{ f.title }}
                            </li>
                            <li v-else class="hr"><br/></li>
                        </template>
                    </ul>
                </div>
                <div class="entityPanel selectorArea2">
                    <h3>地區</h3>
                    <ul>
                        <li :class="{'active': f=== view.selectCity }" v-for="f in view.dataCity" @click="view.selectCity = f" :key="f">
                            {{ f }}
                        </li>
                    </ul>
                </div>
                <div class="entityPanel selectorArea3 fieldSelectorActive">
                    <h3>案場</h3>
                    <ul>
                        <li :class="{'active': view.selectTarget === f.name }" v-for="f in view.dataField" @click="doSelect(f)" :key="f.id">{{ f.name }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <Drawer :title="view.drawerTitle" :width="view.drawerWidth" :closable="false" v-model="view.showDrawer" @on-close="view.drawerName='undefined'">
        <component v-bind:is="view.drawerName"
                   :drawerTitle="view.drawerTitle"
                   :drawerParameter="view.drawerParameter"
                   :drawerName="view.drawerName" :openDrawer="openDrawerInDrawer">
        </component>
    </Drawer>
</template>

<script>
import {Apps} from '@/plugins/gs-plugins';
import Swal from "sweetalert2"

export default {
    name: 'App',
    components: {
        Apps
    },
    setup() {
    },
    computed: {
        pageName() {
            return this.view.pageAreaName || this.$route.name || this.view.pageName
        },
        navData(){
            return this.pageData['nav']
        },
        menuData(){
            if (this.navData){
                let s = this.navData.filter(x=>x.name === this.view.mainMenuName)
                if (s.length > 0) return s[0]["items"]
            }
            return []
        },
        fieldCount(){
            return this.view.selectFieldList.length
        },
    },
    data() {
        return {
            isRouterAlive: true,
            initPath: '',
            isCollapsed: false,
            showApps: false,
            showSelector: false,
            tempTargetInfo: "",
            landingPage: "/patrol/WorkFlows",
            appsHover: null,
            menuRightSideWidth: "30px",
        }
    },
    unmounted() {
        window.removeEventListener('resize', this.view.handleWindowResize);
    },
    mounted() {
        this.initPath = location.pathname
        this.eventBus.on('userInit', this.userInit);
        this.eventBus.on('loginSuccess', () => {
            this.$router.push('/patrol/FieldMap')
        });

        this.view.handleWindowResize();
        window.addEventListener('resize', this.view.handleWindowResize);

        window["alert"] = function (msg, timeout, allowOutsideClick) {
            if (typeof allowOutsideClick === "undefined") allowOutsideClick = true;
            Swal.close()
            setTimeout(function () {
                if (timeout !== undefined) {
                    Swal.fire({
                        title: "",
                        html: msg,
                        timer: timeout,
                        showConfirmButton: false,
                        allowOutsideClick: allowOutsideClick,
                    });
                } else {
                    if (msg) {
                        Swal.fire({
                            title: msg,
                        });
                    }
                }
            }, 100)
        }
        this.view.selectFilter = localStorage.getItem("view_selectFilter") || "全部類型"
        this.view.selectCity = localStorage.getItem("view_selectCity") || "全部地區"
        this.view.selectTarget = localStorage.getItem("view_selectTarget") || "全部案場"
    },
    methods: {
        openDrawerInDrawer(drawerName, drawerTitle, drawerParameter){
            console.log('openDrawerInDrawer', drawerName, drawerTitle, drawerParameter)
            this.view.showDrawer = false
            setTimeout(() => {
                this.view.openDrawer(drawerName, drawerTitle, drawerParameter)
            }, 1000)
        },
        logoClick(){
            if (this.view.isMobile){
                this.view.selectFilter = '全部類型'
                this.view.selectCity = '全部地區'
                this.showSelector = true
                this.tempTargetInfo = this.view.targetInfo
            }else{
                this.$router.push('/patrol/WorkFlows')
            }
        },
        mainMenuMouseOver(event, k){
            if (this.view.showSubMenu && this.view.isTablet) {
                this.mainMenuSelect(event, k)
            }
        },
        mainMenuSelect(event, k) {
            if (this.view.isTablet) {
                console.log('event', event)
                if (this.view.mainMenuName === k.name && event.type !== "mouseover") {
                    this.view.showSubMenu = !this.view.showSubMenu
                } else {
                    this.view.showSubMenu = true
                }
                this.view.mainMenuName = k.name

                const rect = event.target.getBoundingClientRect();
                const clickPositionFromLeft = event.clientX - rect.left;
                let rightSideWidth = window.innerWidth - event.clientX - (rect.width - clickPositionFromLeft);
                if (window.innerWidth - rightSideWidth - rect.width < 100)
                    rightSideWidth = window.innerWidth - 132;
                this.menuRightSideWidth = rightSideWidth + "px";
            } else {
                this.view.mainMenuName = k.name
                let i = k.items[0]
                this.$router.push(`/${i.dir}/${i.page}`)
            }
        },
        checkMenuAvailable(k){
            // 確定選單是否可用
            let o = 0
            let t = 0
            if (k.showInPV) o = 5
            if (k.showInBG) o = 5
            if (k.showInHP) o = 5
            if (k.showInES) o = 5
            if (k.showInOT) o = 5
            if (k.showInPV && this.view.hasPVCase) t += 1
            if (k.showInBG && this.view.hasBGCase) t += 1
            if (k.showInHP && this.view.hasHPCase) t += 1
            if (k.showInES && this.view.hasESCase) t += 1
            if (k.showInOT && this.view.hasOTCase) t += 1
            return (t === 0 && o === 0) || !!(t > 0 && o > 0)
        },
        toggleSelector(){
            this.showSelector = !this.showSelector
            if (this.showSelector){
                this.tempTargetInfo = this.view.targetInfo
            }
        },
        doSelect(f){
            console.log(f)
            this.view.doSelect(f)
            this.showSelector = false
            if (this.tempTargetInfo !== this.view.targetInfo){
                this.reload()
            }
            this.$forceUpdate()
        },
        userInit(){
            if (this.view.isLogin) {
                let that = this
                this.view.loadFieldData(() => {
                    let f = that.view.dataField.filter(x => x.name === that.view.selectTarget)
                    that.doSelect(f[0])
                });
                this.view.loadCaseData();
                this.view.loadEquipmentData();
                this.view.loadWorkerData();
                this.view.loadEmployeeData();
                this.view.loadApplicationUserData();
                if (location.pathname === '' || location.pathname === '/') {
                    this.$router.push(this.landingPage)
                } else {
                    this.$router.push(location.pathname)
                }
            }
        },
        afterLoadFieldData(data) {
            console.log(data)
        },
        afterLoadCaseData(data) {
            console.log(data)
        },
        afterLoadWorkerData(data) {
            console.log(data)
        },
        afterLoadEmployeeData(data){
          console.log(data);
        },
        reload() {
            this.isRouterAlive = false;
            this.$nextTick(() => {
                this.isRouterAlive = true
            });
            return false
        },
        getFormData(object) {
            let formData = new FormData();
            Object.keys(object).forEach(key => formData.append(key, object[key]));
            return formData;
        },
    },
}
</script>

<style scoped lang="stylus">
.mobileMenu
    transition: right 0.5s ease, height 0.3s ease;
    position: fixed;
    background: #fff;
    border: solid 1px #9999;
    z-index: 9999999;
    right: 30px;
    top: 42px;
    border-radius: 4px;
    width: 130px;
    overflow: hidden;
    .ivu-list-item
        height: 42px;
</style>
