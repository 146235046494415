import {defineAsyncComponent} from "vue";
import {createRouter, createWebHistory} from "vue-router";

export {pageData};

let pageData = {
    'layout': [
    ],
    'nav': [
        {
            title: '巡視',
            name: 'patrol',
            items: [
                {dir: 'patrol', title: '天候圖資', page: 'FieldMap'},
                {dir: 'patrol', title: '設備異常', page: 'DeviceError'},
                {dir: 'patrol', title: '案場設備列表', page: 'FieldDeviceTable'},
                {dir: 'patrol', title: '設備狀態列表', page: 'EquipmentList'},
                {dir: 'patrol', title: '監控電腦列表', page: 'DeviceList'},
                {dir: 'patrol', title: '案場圖查閱', page: 'ImageView'},
                {dir: 'patrol', title: '累計發電度數', page: 'Cumulative', showInPV: true, showInBG: true,},
                {dir: 'patrol', title: '落塵系統', page: 'DustSystem', showInPV: true,},
                {dir: 'patrol', title: '發電機列表', page: 'GeneratorList', showInBG: true,},
                {dir: 'patrol', title: '熱泵桶溫監測', page: 'WaterTemperature', showInHP: true,},
                {dir: 'patrol', title: '水錶計量', page: 'WaterFlow', showInBG: true, showInHP: true,},

            ]
        },
        {
            title: '報修',
            name: 'inform',
            items: [
                {dir: 'inform', title: '報修單列表', page: 'IssueList'},
                {dir: 'inform', title: '保固資料', page: 'WarrantyInformation'},
                {dir: 'inform', title: '定期保養記錄', page: 'RegularMaintenance'},
                {dir: 'inform', title: '定期保養週期', page: 'PlannedDispatchList'},
                {dir: 'inform', title: '履歷調閱', page: 'FieldMaintenance'},
                {dir: 'inform', title: '*發電損失', page: 'GenerationLoss', showInPV: true},
                {dir: 'inform', title: '*清洗建議', page: 'CleanRecommend', showInPV: true},
                // {dir: 'inform', title: '診斷分析', page: 'pageDayEvent_2'},
                // {dir: 'inform', title: '初步協助', page: 'pageDayEvent_3'},
            ]
        },
        {
            title: '派工',
            name: 'dispatch',
            items: [
                {dir: 'dispatch', title: '明日派工內容', page: 'DispatchTomorrow'},
                {dir: 'dispatch', title: '派工行事曆', page: 'DispatchCalendar'},
                {dir: 'dispatch', title: '建立派工單', page: 'CreateDispatch'},
                {dir: 'dispatch', title: '派工單列表', page: 'pageDispatchList'},
                // {dir: 'dispatch', title: '人員行事曆', page: 'EmployeeCalendar'},
                {dir: 'dispatch', title: '處理結果回報',page: 'CreateReportNew',},
                {dir: 'dispatch', title: '*處理結果列表',page: 'ReportList',},
                {dir: 'dispatch', title: '*報工打卡', page: 'pageReportWork'},
                {dir: 'dispatch', title: '*拍照上傳', page: 'pageDayEvent_8'},
                {dir: 'dispatch', title: '*履歷填寫', page: 'pageDayEvent_9'},
                // {dir: 'dispatch', title: '派工單列表', page: 'DispatchCalendar'},
                // {dir: 'dispatch', title: '派工行事曆', page: 'Calendar'},
                // {dir: 'dispatch', title: '派工單列表', page: 'DispatchList'},
                // {dir: 'dispatch', title: '派工日曆', page: 'Calendar'},
                // {dir: 'dispatch', title: '派工台', page: 'Dashboard'},
                // {dir: 'dispatch', title: '備料準備', page: 'pageDayEvent_4'},
            ]
        },
        // {
        //     title: '報表',
        //     name: 'report',
        //     items: [
        //         {dir: 'report', title: '*維運統計圖表', page: 'ExternalWork1'},
        //         {dir: 'report', title: '*發電達成率', page: 'ExternalWork2'},
        //         {dir: 'report', title: '*電子表單-低壓IR', page: 'ExternalWork33'},
        //         {dir: 'report', title: '*年度維修物料成本預估表', page: 'ExternalWork44'},
        //         {dir: 'report', title: '*年度維修人力成本預估表', page: 'ExternalWork5'},
        //         {dir: 'report', title: '*年度維修成本預估表', page: 'ExternalWork6'},
        //     ]
        // },
        {
            title: '管理',
            name: 'manage',
            items: [
                {dir: 'manage', title: '案場資料維護', page: 'FieldList'},
                {dir: 'manage', title: '案場設備維護', page: 'FieldDeviceList'},
                {dir: 'manage', title: '案場圖上傳', page: 'FieldImageList'},
                // {dir: 'manage', title: '*供應商', page: 'Supplier'},
                {dir: 'manage', title: '*工班資料', page: 'ExternalWork'},
            ]
        },
        // {
        //     title: '監控',
        //     name: 'monitor',
        //     items:[
        //         {dir:'monitor', title: '*基本監測', page:'BasicMonitor'},
        //         {dir: 'monitor', title: '*逆變器熱圖', page: 'InverterHeatMap'},
        //         {dir: 'monitor', title: '*單一逆變器', page: 'InverterEfficiency'},
        //         {dir: 'monitor', title: '*案場發電量', page: 'FieldGeneration'},
        //         {dir: 'monitor', title: '*案場事件紀錄', page: 'EventRecord'},
        //         {dir: 'monitor', title: '*發電量報表', page: 'GenerationExport'},
        //     ]
        // }
    ],
    'drawer': [
        {dir: 'drawer', title: '圖表', page: 'Chart'},
        {dir: 'drawer', title: '建立派工單', page: 'CreateDispatch'},
        {dir: 'drawer', title: '派工單詳情', page: 'DispatchDetail'},
        {dir: 'drawer', title: '派工單刪除', page: 'DeleteDispatch'},
        {dir: 'drawer', title: '派工單編輯', page: 'EditDispatch'},
        {dir: 'drawer', title: '發電機詳情', page: 'GeneratorDetail'},
        {dir: 'drawer', title: '監控電腦詳情', page: 'DeviceEditor'},
        {dir: 'drawer', title: '任務排程', page: 'CronItemDetail'},
        {dir: 'drawer', title: '保固詳情', page: 'WarrantyDetail'},
        {dir: 'drawer', title: '保養記錄', page: 'CreateMaintainRecord'},
        {dir: 'drawer', title: '案件保養詳細', page: 'caseMaintenance'},

    ],
    'component':[
        {dir: 'components', title: 'HelloWorld', page: 'HelloWorld'},
        {dir: 'components', title: 'HelloWorld2', page: 'HelloWorld2'},
        {dir: 'components', title: 'HelloWorld3', page: 'HelloWorld3'},
        {dir: 'components', title: '任務編輯', page: 'comTaskDataEditor2'},
        {dir: 'components', title: '主要選單', page: 'comMainMenu'},
        {dir: 'components', title: '側邊選單', page: 'comSideMenu'},
        {dir: 'components', title: 'Test', page: 'comTest'},
        {dir: 'components', title: '物料', page: 'comMaterialDetail'},
        {dir: 'components', title: '派工單', page: 'comDispatchDetail'},
        {dir: 'components', title: '客訴維修', page: 'comIssueDetail'},
        {dir: 'components', title: '案件收支明細', page: 'comIncomeDetail'},
        {dir: 'components', title: '車輛編輯', page: 'comCarDetail'},
        {dir: 'components', title: '查看車輛', page: 'comCarParkingView'},
        {dir: 'components', title: '停放車輛', page: 'comCarParking'},
        {dir: 'components', title: '翻譯', page: 'comTsTranslate'},
        {dir: 'components', title: '摘要狀態', page: 'comMonitorCard'},
    ],
    'page':[
        {dir: 'patrol', title: '維運流程', page: 'WorkFlows'},
        {dir: 'dispatch', title: '建立派工單', page: 'CreateDispatch'},
        {dir: 'inform', title: '建立報修單', page: 'CreateIssue'},
        {dir: 'completed', title: '處理結果回報',page: 'CreateReport',},
        {dir: 'inform', title: '案場維運',page: 'FieldMaintenance',},
        {dir: 'patrol', title: '監控電腦列表', page: 'assetsHistory'},
    ],
}


export default {
    install: (app) => {
        app.config.globalProperties.pageData = pageData
        const routes = [];

        function loadComponent(page, title, dir, parentRoute = null) {
            if (typeof page === "undefined") return false;
            dir = dir || 'page';
            let component = defineAsyncComponent(() =>
                import(`@/page/${dir}/${page}.vue`)
            );
            const route = {
                name: `${dir}_${page}`,
                path: `/${dir}/${page}`, component: component
            };
            if (parentRoute) {
                if (!parentRoute.children) parentRoute.children = [];
                parentRoute.children.push(route);
            } else {
                routes.push(route);
            }
            app.component(dir + '_' + page, component);
        }

        // 先處理 layout，確保它們作為父路由存在
        const parentRoutes = {};
        pageData['layout'].forEach(layoutItem => {
            let component = defineAsyncComponent(() =>
                import(`@/page/${layoutItem.dir}/${layoutItem.page}.vue`)
            );
            const parentRoute = {
                path: `/${layoutItem.dir}/${layoutItem.page}`,
                component: component,
                children: []
            };
            routes.push(parentRoute);
            // 將這些 layout 路由添加到全局變量中，便於後續查找
            parentRoutes[layoutItem.page] = parentRoute;
        });

        console.log(parentRoutes);

        // 處理其他路由
        Object.keys(pageData).forEach(key => {
            if (key === 'layout') return;
            let d = pageData[key];
            d.forEach(i => {
                if (!i.dir) i.dir = key;
                let parentRoute = null;
                if (i.items && i.items.length) {
                    loadComponent(i.page, i.title, i.dir, parentRoute);
                    i.items.forEach(j => {
                        if (!j.dir) j.dir = key;
                        if (j.layout in parentRoutes) parentRoute = parentRoutes[j.layout];
                        loadComponent(j.page, j.title, j.dir, parentRoute);
                    });
                } else {
                    loadComponent(i.page, i.title, i.dir);
                }
            });
        });

        const router = createRouter({
            history: createWebHistory(),
            routes,
        })
        app.use(router);
    }
};