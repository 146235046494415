export default class UserSession {
    constructor() {
        this._roleList = [];
        this._isLogin = false;
        this._ssoUrl = '';
        this.currentUser = {};
        this.ssoReady = false;
    }

    get roleList() {
        return Object.freeze([...this._roleList]);
    }

    get ssoUrl() {
        return this._ssoUrl;
    }

    get isLogin() {
        return this._isLogin;
    }

    setRoles(roles) {
        this._roleList = roles;
    }

    hasRole(role) {
        return this.roleList.includes(role);
    }

    checkPermission(permissions) {
        if (!Array.isArray(permissions)) {
            permissions = [permissions];
        }
        return permissions.some(permission => this.roleList.includes(permission));
    }

    updateLoginStatus(status) {
        this._isLogin = status;
    }

    updateUser(user) {
        this.currentUser = Object.freeze({ ...user });
    }

    updateSsoStatus(url) {
        this._ssoUrl = url;
        this.ssoReady = url !== '';
    }
}