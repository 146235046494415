<script>
import {UseDark} from "@vueuse/components";
import loginBox from "./loginBox";
import axios from "axios";
import UserSession from './userSession';
import { globalStateData } from './globalState';

export default {
    name: 'GSApps',
    components: {
        loginBox,
        UseDark
    },
    setup() {
        const gsData = globalStateData();
        return { gsData };
    },
    emits: [],
    props: {
        platformName: String,
        themeName: String,
    },
    computed: {
        mainApps() {
            if (!this.isLogin) return this.appsLink.filter(x => x.cat === 'main' && x.verify === false);
            return this.appsLink.filter(x => x.cat === 'main')
        },
        insideApps() {
            if (!this.isLogin) return this.appsLink.filter(x => x.cat === 'inside' && x.verify === false);
            return this.appsLink.filter(x => x.cat === 'inside')
        },
        otherApps()     {
            return this.appsLink.filter(x => x.cat === 'other')
        },
        isLogin() {
            return this.globalState.isLogin;
        },
    },
    data() {
        return {
            appsHover: null,
            appsLink: [
                {
                    title: '碳排盤查',
                    cat: 'main',
                    verify: false,
                    url: 'https://esg.greenshepherd.com.tw/?r=' + Math.random(),
                    svg: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/c_esg.svg',
                    svg2: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/w_esg.svg',
                },
                {
                    title: '虛擬電廠',
                    cat: 'main',
                    verify: false,
                    url: 'https://vpp.greenshepherd.com.tw/?r=' + Math.random(),
                    svg: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/c_ai.svg',
                    svg2: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/w_ai.svg',
                },
                {
                    title: '能源交易',
                    cat: 'main',
                    verify: false,
                    url: 'https://market.greenshepherd.com.tw/?r=' + Math.random(),
                    svg: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/c_vpp.svg',
                    svg2: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/w_vpp.svg',
                },
                {
                    title: '案件流程',
                    cat: 'inside',
                    verify: false,
                    url: 'https://www.greenshepherd.com.tw/afu?r=' + Math.random(),
                    svg: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/c_afu.svg',
                    svg2: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/w_afu.svg',
                },
                {
                    title: '維運派工',
                    cat: 'inside',
                    verify: false,
                    url: 'https://om.greenshepherd.com.tw/?r=' + Math.random(),
                    svg: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/c_om.svg',
                    svg2: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/w_om.svg',
                },
                {
                    title: '電力交易',
                    cat: 'inside',
                    verify: false,
                    url: 'https://trade.greenshepherd.com.tw/?r=' + Math.random(),
                    svg: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/c_trade.svg',
                    svg2: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/w_trade.svg',
                },
                {
                    title: '人資管理',
                    cat: 'inside',
                    verify: false,
                    url: 'https://hr.greenshepherd.com.tw/?r=' + Math.random(),
                    svg: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/c_afu2.svg',
                    svg2: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/w_afu2.svg',
                },
                {
                    title: '阿福測試區',
                    cat: 'inside',
                    verify: true,
                    url: 'https://afu.greenshepherd.com.tw/?r=' + Math.random(),
                    svg: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/c_afu2.svg',
                    svg2: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/w_afu2.svg',
                },
                {
                    title: '文件簽核',
                    cat: 'inside',
                    verify: true,
                    url: 'https://sign.greenshepherd.com.tw/?r=' + Math.random(),
                    svg: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/c_afu2.svg',
                    svg2: 'https://storage.googleapis.com/static.greenshepherd.com.tw/static/images/apps/w_afu2.svg',
                },
            ],
            messageList: [],
            message: '',
            showLogin: false,
            showApps: false,
            showUser: false,
            showNoti: false,
            localUserSession: new UserSession(),
        }
    },
    mounted() {
        this.ssoCheck();
        this.eventBus.on('loginSuccess', this.processSSOData);
        this.$nextTick(() => {
            document.addEventListener('click', this.handleOutsideClick);
        });
    },
    unmounted() {
        document.removeEventListener('click', this.handleOutsideClick);
    },
    methods: {
        logout() {
            this.api.get('/api/sso/logout').then(() => {
                this.eventBus.emit('userLogout', Object.freeze({
                    ...{},
                    roles: [],
                    isLogin: false,
                }));
                this.globalState.handleUserLogout();
            })
        },
        accountManager() {
            location.href = 'https://account.greenshepherd.com.tw/?r=' + Math.random()
        },
        handleOutsideClick(event) {
            const userBox = this.$el.querySelector('.userBox');
            const appsBox = this.$el.querySelector('.appsBox');
            const notiBox = this.$el.querySelector('.notificationBox');

            if (this.showUser && userBox && !userBox.contains(event.target)) {
                this.showUser = false;
            }

            if (this.showApps && appsBox && !appsBox.contains(event.target)) {
                this.showApps = false;
            }

            if (this.showNoti && notiBox && !notiBox.contains(event.target)) {
                this.showNoti = false;
            }
        },
        toggleLoginBox() {
            this.showLogin = true;
        },
        toggleUserBox() {
            this.showUser = !this.showUser;
            this.showNoti = false;
            this.showApps = false;
        },
        toggleNotificationBox() {
            this.showNoti = !this.showNoti;
            this.showUser = false;
            this.showApps = false;
        },
        toggleAppsBox() {
            this.showApps = !this.showApps;
            this.showNoti = false;
            this.showUser = false;
        },
        handleMaskClick() {
            this.showLogin = false
        },
        handleLoginSuccess(data) {
            this.showLogin = false
            this.processUserRoles(data)
            this.eventBus.emit('loginSuccess', data)
        },
        processSSOData(data) {
            this.localUserSession.updateLoginStatus(data.isLogin);
            this.localUserSession.updateSsoStatus(data.url);
            this.processUserRoles(data.data);
            const checkData = {
                userData: this.localUserSession.currentUser,
                roles: this.localUserSession.roleList,
                isLogin: this.localUserSession.isLogin,
            };
            // 更新 Pinia store
            this.globalState.setUserSession(this.localUserSession);
            this.globalState.handleUserInit(checkData);
        },
        async ssoCheck() {
            try {
                const serviceUrl = this.getServiceUrl(location.hostname);
                const response = await axios.get(`/api/sso/check?indialog=true&service=${encodeURI(serviceUrl)}`);
                const {data} = response;
                this.processSSOData(data);
                this.addDevelopmentRoles();

                if (!this.localUserSession.isLogin) {
                    this.toggleLoginBox();
                }
            } catch (error) {
                console.error('SSO 檢查失敗:', error);
                throw error;
            }
        },
        getServiceUrl(hostname) {
            if (hostname === 'localhost' || hostname === '127.0.0.1') {
                let url = `${location.protocol}//${hostname}:${location.port}`;
                return url.endsWith(':') ? url.slice(0, -1) : url;
            }
            return hostname.replace('.greenshepherd.com.tw', '');
        },
        processUserRoles(userData) {
            try {
                const roles = userData.user_roles ? userData.user_roles.split(',') : [];
                this.localUserSession.setRoles(roles);
                this.localUserSession.updateUser(userData);
            } catch (err) {
                console.error('處理用戶角色時出錯:', err);
                this.localUserSession.setRoles([]);
            }
        },
        isDevelopmentEnvironment() {
            return ['localhost', '127.0.0.1'].includes(location.hostname);
        },
        addDevelopmentRoles() {
            if (!this.isDevelopmentEnvironment()) return;
            const devRoles = ['admin', 'hr', 'dev'];
            this.localUserSession.setRoles([...new Set([...this.localUserSession.roleList, ...devRoles])]);
        },
    },
}
</script>

<template>
    <div class="appsArea" :class="{[themeName || 'light']: true, 'isTablet': view.isTablet, 'isMobile': view.isMobile}">
        <div class="apps">
            <a @click.stop.prevent="toggleLoginBox" v-if="!isLogin" class="btnUserLogin">登入</a>
            <a @click.stop.prevent="toggleUserBox" v-if="isLogin"><div class="userIcon" :style="{'backgroundImage': globalState.currentUser.avatar}"></div></a>
            <a @click.stop.prevent="toggleAppsBox"><Icon type="ios-apps"/></a>
            <a @click.stop.prevent="toggleNotificationBox" v-if="isLogin"><Icon type="md-notifications"/></a>
        </div>

        <div class="notificationBox" v-show="showNoti" :class="{'animation-drop-in': showNoti}">
            <ul v-if="messageList.length === 0">
                <li style="padding: 138px 0; text-align: center;">無通知</li>
            </ul>
            <ul>
                <li v-for="msg in messageList" :key="msg" @click="message = msg">{{ msg }}</li>
            </ul>
            <div v-if="message !== ''">
                <p>{{ message }}</p>
            </div>
        </div>

        <div class="userBox" v-show="showUser" :class="{'animation-drop-in': showUser}">
            <div class="userInfo">
                <div class="userIconBig" :style="{'backgroundImage': globalState.currentUser.avatar}"></div>
                <h1>{{ globalState.currentUser.user_name }}，你好！</h1>
                <!-- <router-link class="serviceBtn" :to="'/user/memberPage'">會員中心</router-link>-->
            </div>
            <div class="userButtons" v-if="isLogin" @click="globalState.intoDebug">
                <a class="accountBtn" href="#" @click.prevent.stop="accountManager">帳號管理</a>
                <a class="logoutBtn" href="#" @click.prevent.stop="logout"><Icon type="md-exit" />登出</a>
            </div>
            <div class="userDarkMode" v-if="isLogin">
                <span class="darkMode">
                    深色模式
                </span>
                <UseDark v-slot="{ isDark, toggleDark }">
                    <div @click="toggleDark()" class="darkModeArea">
                        <Icon v-show="isDark" type="ios-moon"/>
                        <Icon v-show="!isDark" type="ios-sunny"/>
                    </div>
                </UseDark>
            </div>
        </div>

        <div class="appsBox" v-show="showApps" :class="{'animation-drop-in': showApps}">
            <ul>
                <li v-for="app in mainApps" :key="app.title" @click="location.href = app.url" @mouseover="appsHover = app.title"
                    @mouseleave="appsHover = ''"><a :href="app.url">
                    <img :src="appsHover === app.title ? app.svg : app.svg2"/><br/>{{ app.title }}
                </a></li>
            </ul>
            <ul>
                <li v-for="app in insideApps" :key="app.title" @click="location.href = app.url" @mouseover="appsHover = app.title"
                    @mouseleave="appsHover = ''"><a :href="app.url">
                    <img :src="appsHover === app.title ? app.svg : app.svg2"/><br/>{{ app.title }}
                </a></li>
            </ul>
        </div>
        <loginBox :platformName="platformName" :view="globalState" v-if="showLogin" @maskClick="handleMaskClick" @loginSuccess="handleLoginSuccess"  />
    </div>
</template>

<style src="./apps.styl" lang="stylus"></style>