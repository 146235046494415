import {defineStore} from 'pinia';
import {useDark} from "@vueuse/core";
import {DataPool} from "./dataPool";
import mitt from "mitt";

let originalSetInterval = window.setInterval;
let originalSetTimeout = window.setTimeout;
let intervalTimerList = [];
let timeOutTimerList=[]

export const globalStateData = defineStore('globalStateData', {
    state: () => {
        return {
            userSession: null,
            isDark: useDark(),
            isMobile: true,
            isTablet: false,
            pageName: '',
            pageTitle: '',
            menuTitle: '',
            mainMenuName: '',
            drawerInfo: {
                show: false,
                name: '',
                title: '',
                width: 600,
                parameter: {},
            },
            isChecked: false,
            windowWidth: window.innerWidth,
            routeFrom: null,
            routeTo: null,
        }
    },
    getters: {
        ssoUrl: (state) => state.userSession ? state.userSession.ssoUrl : '',
        isLogin: (state) => state.userSession ? state.userSession.isLogin : false,
        roleList: (state) => state.userSession ? state.userSession.roleList : [],
        currentUser: (state) => state.userSession ? state.userSession.currentUser : {},
    },
    actions: {
        setUserSession(session) {
            this.userSession = session;
        },
        hasRole(role) {
            return this.userSession ? this.userSession.hasRole(role) : false;
        },
        checkPermission(permissions) {
            return this.userSession ? this.userSession.checkPermission(permissions) : false;
        },
        handleUserLogout() {
            location.reload()
        },
        handleUserInit(data){
            this.isChecked = true;
            this.eventBus.emit('userInit', data);
        },
        handleWindowResize() {
            const windowWidth = window.innerWidth;
            // let path=location.pathname
            try{
                let n = 0.8;
                if (window.innerWidth >= 769) n = 0.6;
                if (window.innerWidth >= 1024) n = 0.5;
                this.drawerInfo.width = window.innerWidth * n;
            }catch(err){
                console.log(err);
            }
            const mobileWidthThreshold = 1024;
            this.isMobile = windowWidth <= mobileWidthThreshold;
        },
        openDrawer(drawerName, drawerTitle, drawerParameter) {
            drawerTitle = drawerTitle || ''
            drawerParameter = drawerParameter || {}
            this.drawerInfo.show = true
            this.drawerInfo.title = drawerTitle
            this.drawerInfo.parameter = drawerParameter
            setTimeout(() => {
                this.drawerInfo.name = drawerName
            }, 330)
        },
        setPageInterval(callback, ms, keep = false) {
            keep = keep || false
            let i = originalSetInterval(callback, ms);
            if (!keep) intervalTimerList.push(i);
        },
        clearPageInterval() {
            intervalTimerList.forEach(i => {
                clearInterval(i);
            });
            intervalTimerList = [];
        },
        setPageTimeout(callback, ms, keep = false){
            keep = keep || false
            let i = originalSetTimeout(callback, ms);
            if (!keep) timeOutTimerList.push(i);
            return i
        },
        clearPageTimeout(){
            timeOutTimerList.forEach(i=>{clearTimeout(i);});
            timeOutTimerList=[];
        },
        beforePageChange(to, from) {
            this.clearPageInterval();
            this.clearPageTimeout();
            this.routeFrom = from;
            this.routeTo = to;
            if (to.meta && to.meta.permissions) {
                if (!this.checkPermission(to.meta.permissions)) {
                    if (this.isChecked){
                        return {name: 'error_AccessDenied'};
                    }else{
                        return {name: 'error_UserNotReady'};
                    }
                }
            }
        },
        afterPageChange() {
            let path = location.pathname;
            let pageName = path.split('/').join('_').substring(1);
            if (pageName.length === 1) pageName = 'components_' + pageName;
            this.pageName = pageName;
            setTimeout(() => {
                this.mainMenuName = pageName.split("_")[0];
            }, 30);
            const from = this.routeFrom;
            const to = this.routeTo;
            this.eventBus.emit('afterPageChange', {path, from, to, pageName});
        },
    },
})

export default {
    install: (app) => {
        const bus = mitt()
        const gs = globalStateData()
        const dp = app.config.globalProperties.dataPool || new DataPool()
        const router = app.config.globalProperties.$router
        gs.dataPool = dp
        gs.eventBus = bus
        if (router){
            router.beforeEach(async (to, from) => {
                return gs.beforePageChange(to, from)
            })
            router.afterEach((to, from, failure) => {
                if (!failure) gs.afterPageChange()
            })
        }
        const view = app.config.globalProperties.view
        if (view){
            view.dataPool = dp
            view.eventBus = bus
            view.globalState = gs
            const vsProxy = new Proxy(view, {
                get(target, key) {
                    if (key in target) return Reflect.get(target, key);
                    return Reflect.get(gs, key);
                },
                set(target, key, value) {
                    if (key in target) {
                        Reflect.set(target, key, value);
                        return true;
                    }
                    Reflect.set(gs, key, value);
                    return true;
                }
            });
            app.config.globalProperties.vs = vsProxy;
            app.config.globalProperties.view = vsProxy;
        }
        app.config.globalProperties.dataPool = dp
        app.config.globalProperties.eventBus = bus;
        app.config.globalProperties.gs = gs;
        app.config.globalProperties.globalState = gs;
    }
}